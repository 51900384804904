<template>
  <div>
    <div class="mx-1">
      <!-- <b-breadcrumb
        class="breadcrumb pb-2 pt-1 noprint"
        :items="breadcrumb_items"
      ></b-breadcrumb> -->
      <h3 class="mt-5">
        {{
          $getVisibleNames(
            "evaluations2.rubricpreset",
            false,
            "Modelo de Pauta"
          )
        }}
        <AlternativeNameButton
          :table_name="'evaluations2.rubricpreset'"
          :allows_crud="allows_crud"
        ></AlternativeNameButton>
      </h3>
      <div style="display: flex">
        <div style="text-align: left">
          <ButtonMedium
            v-can="'evaluations2.add_rubricpreset'"
            v-if="allows_crud"
            :tooltip_text="`Agregar ${$getVisibleNames(
              'evaluations2.rubricpreset',
              false,
              'Modelo de Pauta'
            )}`"
            :text_button="`Agregar`"
            :click_button="() => $bvModal.show(`new-rubric_preset-modal`)"
            :custom_class="`mb-3`"
            :icon="'plus'"
            :variant="'primary'"
          >
          </ButtonMedium>
          <!-- <button
            v-can="'evaluations2.add_rubricpreset'"
            v-if="allows_crud"
            class="btn btn-secondary btn-sm"
            @click="$bvModal.show(`new-rubric_preset-modal`)"
          >
            Agregar
            {{
              $getVisibleNames(
                "evaluations2.rubricpreset",
                false,
                "Modelo de Pauta"
              )
            }}
          </button> -->
        </div>
      </div>
      <b-table-simple striped responsive="sm" bordered>
        <b-thead>
          <b-tr>
            <b-th
              v-if="allows_crud"
              style="width: 50px"
              class="text-center"
            ></b-th>
            <b-th style="width: 20%" class="text-center">
              <strong>
                {{
                  $getVisibleNames(
                    "evaluations2.rubricpreset",
                    true,
                    "Modelos de Pauta"
                  )
                }}
                <!-- Ponderación actions -->
              </strong>
            </b-th>
            <b-th style="width: 20%" class="text-center"> Descripción </b-th>
            <b-th style="width: 60%" class="text-center">
              <!-- {{
                $getVisibleNames(
                  "evaluations.observationachievement",
                  true,
                  "Niveles de Logro"
                )
              }} -->
            </b-th>
            <b-th v-if="allows_crud" style="width: 100px" class="text-center">
            </b-th>
          </b-tr>
        </b-thead>
        <draggable
          v-model="rubricPresetDraggable"
          :disabled="!allows_crud"
          tag="tbody"
          handle=".handle"
        >
          <template v-for="rubric_preset in rubricPresetDraggable">
            <b-tr :key="`${rubric_preset.id}`">
              <b-td
                class="handle draggable-style grabbable-cursor"
                v-if="allows_crud"
              >
                <div>
                  <b-icon
                    v-if="allows_crud"
                    class="move-icon"
                    icon="arrows-expand"
                    scale="1.2"
                  ></b-icon>
                  <span v-else>
                    {{ rubric_preset.order }}
                  </span>
                </div>
              </b-td>
              <b-td class="text-center align-text-center">
                {{ rubric_preset.title }}
              </b-td>
              <b-td class="text-center">
                <div
                  class="rich-text-content"
                  v-html="rubric_preset.description"
                ></div>
              </b-td>
              <b-td>
                <RubricPresetUnit
                  :RubricPreset="rubric_preset"
                  :allows_crud="allows_crud"
                >
                </RubricPresetUnit>
                <!-- <div class="tags-container">
                  <div
                    v-for="item in rubricPresetUnitsFilter(
                      rubric_preset.rubric_preset_units
                    )"
                    :key="item.id"
                    class="tag-div"
                  >
                    {{ item.title }}
                  </div>
                </div> -->
              </b-td>
              <b-td v-if="allows_crud">
                <div class="container-btn-rubrics">
                  <ButtonMedium
                    :tooltip_text="`Editar ${$getVisibleNames(
                      'evaluations2.rubricpreset',
                      false,
                      'Modelo de Pauta'
                    )}`"
                    :text_button="``"
                    :click_button="
                      () =>
                        $bvModal.show(
                          `edit-rubric_preset-modal-${rubric_preset.id}`
                        )
                    "
                    :icon="'square'"
                    :variant="'primary'"
                  >
                  </ButtonMedium>
                  <!-- <b-button
                    class="edit-btn"
                    variant="primary"
                    size="sm"
                    @click="
                      $bvModal.show(
                        `edit-rubric_preset-modal-${rubric_preset.id}`
                      )
                    "
                    v-b-tooltip.v-secondary.noninteractive="
                      `Editar ${$getVisibleNames(
                        'evaluations2.rubricpreset',
                        false,
                        'Modelo de Pauta'
                      )}`
                    "
                  >
                    <b-icon-pencil-square></b-icon-pencil-square>
                  </b-button> -->
                  <ButtonMedium
                    :tooltip_text="`Eliminar ${$getVisibleNames(
                      'evaluations2.rubricpreset',
                      false,
                      'Modelo de Pauta'
                    )}`"
                    :text_button="``"
                    :click_button="
                      () => askForDeleteRubricPreset(rubric_preset.id)
                    "
                    :custom_class="`mt-1 p-1`"
                    :icon="'trash'"
                    :variant="'danger'"
                  >
                  </ButtonMedium>
                  <!-- <b-button
                    class="delete-btn mt-1"
                    variant="danger"
                    size="sm"
                    @click="askForDeleteRubricPreset(rubric_preset.id)"
                    v-b-tooltip.v-secondary.noninteractive="
                      `Eliminar ${$getVisibleNames(
                        'evaluations2.rubricpreset',
                        false,
                        'Modelo de Pauta'
                      )}`
                    "
                    ><b-icon-trash></b-icon-trash
                  ></b-button> -->
                </div>
                <b-modal
                  :id="`edit-rubric_preset-modal-${rubric_preset.id}`"
                  :title="`Editar ${$getVisibleNames(
                    'evaluations2.rubricpreset',
                    false,
                    'Modelo de Pauta'
                  )}`"
                  size="lg"
                  hide-footer
                >
                  <RubricPresetForm
                    :RubricPreset="rubric_preset"
                    @updated="slotUpdatedRubricPreset"
                  ></RubricPresetForm>
                </b-modal>
              </b-td>
            </b-tr>
          </template>
        </draggable>
        <template v-if="rubricPresetDraggable.length == 0">
          <tbody>
            <tr>
              <td :colspan="allows_crud ? 5 : 4">No hay datos que mostrar.</td>
            </tr>
          </tbody>
        </template>
      </b-table-simple>
    </div>
    <b-modal
      :id="`new-rubric_preset-modal`"
      :hide-footer="true"
      :title="`Crear ${$getVisibleNames(
        'evaluations2.rubricpreset',
        false,
        'Modelo de Pauta'
      )}`"
      size="lg"
    >
      <RubricPresetForm @created="slotCreatedRubricPreset"></RubricPresetForm>
    </b-modal>
  </div>
</template>
<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";
import draggable from "vuedraggable";

export default {
  name: "RubricPreset",
  components: {
    draggable,
    RubricPresetForm: () =>
      import(
        "@/components/new-evaluations/Rubrics/RubricsPreset/RubricPresetForm"
      ),
    RubricPresetUnit: () =>
      import(
        "@/components/new-evaluations/Rubrics/RubricsPreset/RubricPresetUnit"
      ),
    AlternativeNameButton: () =>
      import("@/components/reusable/AlternativeNameButton"),
    ButtonMedium: () =>
      import("@/components/reusable/Buttons/Medium/ButtonMedium"),
  },
  props: {
    allows_crud: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      input_search: "",
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      rubric_presets: names.RUBRIC_PRESETS,
      rubric_preset_units: names.RUBRIC_PRESET_UNITS,
    }),
    rubricPresetDraggable: {
      get() {
        return [...this.rubric_presets].sort((a, b) => a.order - b.order);
      },
      set(list) {
        list.forEach((element, index) => {
          if (!isNaN(element.id)) {
            let payload = {
              rubric_preset_id: element.id,
              item: {
                order: index + 1,
              },
            };
            this.$store.dispatch(names.PATCH_RUBRIC_PRESET, payload);
          }
        });
      },
    },
    // searchRubricPreset: function () {
    //   return this.rubric_presets
    //     .filter((item) => {
    //       return (
    //         item.name.toLowerCase().includes(this.input_search.toLowerCase()) ||
    //         item.description
    //           .toLowerCase()
    //           .includes(this.input_search.toLowerCase())
    //       );
    //     })
    //     .sort(function (a, b) {
    //       return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    //     });
    // },

    // breadcrumb_items() {
    //   return [
    //     { text: "Definiciones Curriculares", active: true },
    //     {
    //       text: this.$getVisibleNames(
    //         "evaluations2.rubricpreset",
    //         false,
    //         "Modelo de Pauta"
    //       ),
    //       active: true,
    //     },
    //   ];
    // },
  },
  methods: {
    rubricPresetUnitsFilter(rubric_preset_unit_ids) {
      return this.rubric_preset_units
        .filter((x) => rubric_preset_unit_ids.includes(x.id))
        .sort((a, b) => b.order - a.order);
    },
    askForDeleteRubricPreset(rubric_preset_id) {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "evaluations2.rubricpreset",
          false,
          "Modelo de Pauta"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(names.DELETE_RUBRIC_PRESET, rubric_preset_id)
            .then(() => {
              toast(
                String(
                  this.$getVisibleNames(
                    "evaluations2.rubricpreset",
                    false,
                    "Modelo de Pauta"
                  ) + " eliminada."
                )
              );
            });
        }
      });
    },
    slotCreatedRubricPreset() {
      this.$bvModal.hide("new-rubric_preset-modal");
    },
    slotUpdatedRubricPreset(rubric_preset) {
      this.$bvModal.hide(`edit-rubric_preset-modal-${rubric_preset.id}`);
    },
  },
  created() {
    if (this.user) {
      this.$store.dispatch(names.FETCH_RUBRIC_PRESETS);
      this.$store.dispatch(names.FETCH_RUBRIC_PRESET_UNITS);
    } else {
      this.$store.dispatch("logOut");
    }
  },
};
</script>

<style scoped src="@/utils/rich_text_editor.css">
</style>
<style scoped>
.edit-btn {
  padding: 3px;
}
.delete-btn {
  padding: 3px;
}
.container-btn-rubrics {
  display: flex;
  flex-direction: column;
}
.draggable-style {
  background: var(--primary-color);
}
.draggable-style:hover {
  background-color: var(--kl-menu-color);
  color: white;
}
td,
table {
  padding: 0.3rem;
  vertical-align: middle;
  width: -webkit-fill-available;
}
th {
  background: var(--primary-color) !important;
  padding: 0px 5px;
  text-align: center !important;
}
.breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
  margin-left: 2.8em !important;
}
.breadcrumb-item.active {
  color: #2f9ae1;
}
.breadcrumb > li + li:before {
  color: #2f9ae1;
  content: "❯ ";
  padding: 0;
  padding-right: 0.65em;
}
.grabbable-cursor {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.grabbable-cursor:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.move-icon {
  margin: auto;
  cursor: grab;
}
</style>