var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mx-1"},[_c('h3',{staticClass:"mt-5"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "evaluations2.rubricpreset", false, "Modelo de Pauta" ))+" "),_c('AlternativeNameButton',{attrs:{"table_name":'evaluations2.rubricpreset',"allows_crud":_vm.allows_crud}})],1),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticStyle:{"text-align":"left"}},[(_vm.allows_crud)?_c('ButtonMedium',{directives:[{name:"can",rawName:"v-can",value:('evaluations2.add_rubricpreset'),expression:"'evaluations2.add_rubricpreset'"}],attrs:{"tooltip_text":`Agregar ${_vm.$getVisibleNames(
            'evaluations2.rubricpreset',
            false,
            'Modelo de Pauta'
          )}`,"text_button":`Agregar`,"click_button":() => _vm.$bvModal.show(`new-rubric_preset-modal`),"custom_class":`mb-3`,"icon":'plus',"variant":'primary'}}):_vm._e()],1)]),_c('b-table-simple',{attrs:{"striped":"","responsive":"sm","bordered":""}},[_c('b-thead',[_c('b-tr',[(_vm.allows_crud)?_c('b-th',{staticClass:"text-center",staticStyle:{"width":"50px"}}):_vm._e(),_c('b-th',{staticClass:"text-center",staticStyle:{"width":"20%"}},[_c('strong',[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "evaluations2.rubricpreset", true, "Modelos de Pauta" ))+" ")])]),_c('b-th',{staticClass:"text-center",staticStyle:{"width":"20%"}},[_vm._v(" Descripción ")]),_c('b-th',{staticClass:"text-center",staticStyle:{"width":"60%"}}),(_vm.allows_crud)?_c('b-th',{staticClass:"text-center",staticStyle:{"width":"100px"}}):_vm._e()],1)],1),_c('draggable',{attrs:{"disabled":!_vm.allows_crud,"tag":"tbody","handle":".handle"},model:{value:(_vm.rubricPresetDraggable),callback:function ($$v) {_vm.rubricPresetDraggable=$$v},expression:"rubricPresetDraggable"}},[_vm._l((_vm.rubricPresetDraggable),function(rubric_preset){return [_c('b-tr',{key:`${rubric_preset.id}`},[(_vm.allows_crud)?_c('b-td',{staticClass:"handle draggable-style grabbable-cursor"},[_c('div',[(_vm.allows_crud)?_c('b-icon',{staticClass:"move-icon",attrs:{"icon":"arrows-expand","scale":"1.2"}}):_c('span',[_vm._v(" "+_vm._s(rubric_preset.order)+" ")])],1)]):_vm._e(),_c('b-td',{staticClass:"text-center align-text-center"},[_vm._v(" "+_vm._s(rubric_preset.title)+" ")]),_c('b-td',{staticClass:"text-center"},[_c('div',{staticClass:"rich-text-content",domProps:{"innerHTML":_vm._s(rubric_preset.description)}})]),_c('b-td',[_c('RubricPresetUnit',{attrs:{"RubricPreset":rubric_preset,"allows_crud":_vm.allows_crud}})],1),(_vm.allows_crud)?_c('b-td',[_c('div',{staticClass:"container-btn-rubrics"},[_c('ButtonMedium',{attrs:{"tooltip_text":`Editar ${_vm.$getVisibleNames(
                    'evaluations2.rubricpreset',
                    false,
                    'Modelo de Pauta'
                  )}`,"text_button":``,"click_button":() =>
                      _vm.$bvModal.show(
                        `edit-rubric_preset-modal-${rubric_preset.id}`
                      ),"icon":'square',"variant":'primary'}}),_c('ButtonMedium',{attrs:{"tooltip_text":`Eliminar ${_vm.$getVisibleNames(
                    'evaluations2.rubricpreset',
                    false,
                    'Modelo de Pauta'
                  )}`,"text_button":``,"click_button":() => _vm.askForDeleteRubricPreset(rubric_preset.id),"custom_class":`mt-1 p-1`,"icon":'trash',"variant":'danger'}})],1),_c('b-modal',{attrs:{"id":`edit-rubric_preset-modal-${rubric_preset.id}`,"title":`Editar ${_vm.$getVisibleNames(
                  'evaluations2.rubricpreset',
                  false,
                  'Modelo de Pauta'
                )}`,"size":"lg","hide-footer":""}},[_c('RubricPresetForm',{attrs:{"RubricPreset":rubric_preset},on:{"updated":_vm.slotUpdatedRubricPreset}})],1)],1):_vm._e()],1)]})],2),(_vm.rubricPresetDraggable.length == 0)?[_c('tbody',[_c('tr',[_c('td',{attrs:{"colspan":_vm.allows_crud ? 5 : 4}},[_vm._v("No hay datos que mostrar.")])])])]:_vm._e()],2)],1),_c('b-modal',{attrs:{"id":`new-rubric_preset-modal`,"hide-footer":true,"title":`Crear ${_vm.$getVisibleNames(
      'evaluations2.rubricpreset',
      false,
      'Modelo de Pauta'
    )}`,"size":"lg"}},[_c('RubricPresetForm',{on:{"created":_vm.slotCreatedRubricPreset}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }